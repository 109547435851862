import { IAppStateModel } from '@models/IAppStateModel';
import { createSelector } from 'reselect';

export const $currentUser = (state: IAppStateModel) => state.user;

export const $userTeamId = createSelector($currentUser, currentUser => currentUser?.user_team);
export const $userPermissions = createSelector($currentUser, currentUser => currentUser?.user_permissions);

export const $isStaff = createSelector($currentUser, currentUser => currentUser?.is_staff);
export const $isDesigner = createSelector($currentUser, currentUser => currentUser?.is_designer);
export const $isClient = createSelector($currentUser, currentUser => currentUser?.is_client);

export const $isDesignerAdmin = createSelector(
    $currentUser,
    currentUser => currentUser?.is_designer && currentUser?.permission_level === 'LVL1',
);

export const $isDesignerSupervisor = createSelector(
    $currentUser,
    currentUser => currentUser?.is_designer && currentUser?.permission_level === 'LVL2',
);

export const $isDesignerAdminOrSupervisor = createSelector(
    $currentUser,
    currentUser => currentUser?.is_designer && currentUser?.permission_level !== 'LVL3',
);

export const $isDesignerMember = createSelector(
    $currentUser,
    currentUser => currentUser?.is_designer && currentUser?.permission_level === 'LVL3',
);

export const $isClientAdmin = createSelector(
    $currentUser,
    currentUser => currentUser?.is_client && currentUser?.permission_level === 'LVL1',
);

export const $isClientManager = createSelector(
    $currentUser,
    currentUser => currentUser?.is_client && currentUser?.permission_level === 'LVL2',
);

export const $isClientAdminOrSupervisor = createSelector(
    $currentUser,
    currentUser => currentUser?.is_client && currentUser?.permission_level !== 'LVL3',
);

export const $isClientMember = createSelector(
    $currentUser,
    currentUser => currentUser?.is_client && currentUser?.permission_level === 'LVL3',
);

export const $isAdmin = createSelector($currentUser, currentUser => currentUser?.permission_level === 'LVL1');
export const $isManager = createSelector($currentUser, currentUser => currentUser?.permission_level === 'LVL2');
export const $isMember = createSelector($currentUser, currentUser => currentUser?.permission_level === 'LVL3');

export const $hasSparkleSubscription = createSelector($currentUser, currentUser => currentUser?.hasSparkleSubscription ?? false);
