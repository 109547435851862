import { Fragment, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@mui/material';
import { Button, MenuButton, MenuAccordion } from '@components/index';
import { FlexColumn } from '@components/wrappers/Flex/Flex.wrapper';
import { LogoSparkleLg } from '@assets/logos/logo-collection';
import { Panel } from '@layout/index';
import { Spacing, WHITE } from '@style/theme';
import { OutlinePlusCircle } from '@assets/icons';
import { APP_NAVIGATION, NAVIGATION_ITEMS } from '@configs/sidebar.config';
import { $selectAllBrandkits } from '@store/brandkit/brandkit.selectors';
import TaskRequestModal from '@features/Task/TaskRequestModal/TaskRequestModal.component';
import { useNavigate } from 'react-router';
import UserSelector from '@components/user/UserSelector/UserSelector.component';
import { clearSelectedBrandkit } from '@store/brandkit/brandkit.actions';
import { isInIframe } from '@helper/isInIframe';
import { sendMessageToMadgicx } from '@utils/sendMessage';
import PopoverUser from './PopoverUser/PopoverUser.component';
import { useUser } from '@hooks/useUser';
import Image from '@components/common/Image/Image.component';
import { SUPERUSERS } from '@configs/superusers';
import { OutlineData } from '@assets/icons/system.icon';
import { fileApi } from '@api/modules/apiFile';
import {$hasSparkleSubscription} from "@store/user/user.selectors";

const style = {
    root: {
        bgcolor: WHITE,
        width: 200,
        justifyContent: 'space-between',
        transition: 'width 0.25s, opacity 0.5s ease-out',
    },
    navigation: {
        mt: Spacing.LG,
        alignItems: 'center',
        gap: Spacing.LG,
        width: 200,
    },
    madgicxControls: {
        alignItems: 'center',
        gap: Spacing.SM,
        my: Spacing.LG,
    },
    divider: {
        width: 160,
        my: Spacing.MD,
    },
    userControls: {
        gap: Spacing.SM,
        alignItems: 'center',
    },
};

export default memo(function NavInner() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hasSparkleSubscription = useSelector($hasSparkleSubscription);

    const { user_email, permissions, is_trial_end } = useUser();

    const allBrandkits = useSelector($selectAllBrandkits);

    const canAddTask = useMemo(
        () => permissions?.includes('can_add_tasks') || permissions?.includes('can_add_tasks_for_clients'),
        [permissions],
    );

    const [selectMenuItem, setSelectMenuItem] = useState<string>('');
    const selectedMenuItem = useMemo(() => NAVIGATION_ITEMS?.find(item => item === selectMenuItem), [selectMenuItem]);

    const [openModal, setOpenModal] = useState<boolean>(false);

    useEffect(() => {
        let mounted = true;

        if (mounted) {
            const brandkitNav = APP_NAVIGATION.find(item => item.label === 'Brand kits');

            if (allBrandkits.length > 0) {
                const brandkitToOptions = allBrandkits.map(brandkit => ({
                    id: brandkit.id,
                    label: brandkit.brand_name,
                    onClick: () => {},
                    selected: false,
                    path: `/brandkit/${brandkit.id}`,
                }));

                if (brandkitNav) {
                    brandkitNav.options!.length = 0;

                    brandkitToOptions.forEach(option => {
                        brandkitNav.options!.push(option);
                    });
                }
            } else {
                if (brandkitNav) brandkitNav.options!.length = 0;
            }
        }

        return function cleanup() {
            mounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allBrandkits, APP_NAVIGATION]);

    return (
        <>
            {openModal && <TaskRequestModal title="Request's Details" onClose={() => setOpenModal(false)} />}
            <Panel muiStyle={{ ...style.root }}>
                <FlexColumn muiStyle={{ ...style.navigation }}>
                    <Image src={LogoSparkleLg} width={127} />

                    {APP_NAVIGATION?.map(menu => {
                        if (permissions?.includes(menu.permission)) {
                            if (menu.dropdown) {
                                return (
                                    <MenuAccordion
                                        key={menu.label}
                                        label={menu.label}
                                        startIcon={menu.startIcon}
                                        dropdown={menu.dropdown}
                                        selected={selectedMenuItem === menu.label}
                                        onClick={() => {
                                            setSelectMenuItem(previousMenuItem => menu.label);
                                        }}
                                        options={menu.options}
                                        addButtonLabel={menu.addButtonLabel}
                                        addButtonAllowed={permissions?.includes(menu.addButtonPermission ?? '')}
                                        onAddPath={menu.onAddPath}
                                        onAddClick={() => {
                                            if (menu.addButtonLabel === 'Add Brand Kit') {
                                                clearSelectedBrandkit(dispatch);
                                            }
                                        }}
                                    />
                                );
                            } else {
                                return (
                                    <MenuButton
                                        key={menu.label}
                                        label={menu.label}
                                        startIcon={menu.startIcon}
                                        path={menu.path}
                                        selected={selectedMenuItem === menu.label}
                                        onClick={() => {
                                            setSelectMenuItem(previousMenuItem => menu.label);

                                            menu.path && navigate(menu.path);
                                        }}
                                        muiStyle={{ width: 160 }}
                                    />
                                );
                            }
                        } else {
                            return null;
                        }
                    })}
                </FlexColumn>

                <FlexColumn muiStyle={{ ...style.madgicxControls }}>
                    {user_email && SUPERUSERS.includes(user_email) && (
                        <Button
                            label="Export Data"
                            variant="flat"
                            startIcon={<OutlineData />}
                            onClick={() => fileApi.downloadExportData()}
                            width={160}
                        />
                    )}
                    {isInIframe() && (
                        <Fragment>
                            {!hasSparkleSubscription && <Button
                                label={is_trial_end ? 'Start a $0 trial' : 'Upgrade Now'}
                                variant="stroke"
                                onClick={() => sendMessageToMadgicx('UPGRADE')}
                                width={160}
                            />}
                            <Button
                                label="Book a Call"
                                variant="flat"
                                onClick={() => sendMessageToMadgicx('BOOK_A_CALL')}
                                width={160}
                            />
                        </Fragment>
                    )}

                    {canAddTask && (
                        <Button
                            label="Create Task"
                            startIcon={<OutlinePlusCircle />}
                            onClick={() => setOpenModal(previousState => true)}
                            width={160}
                        />
                    )}

                    <Divider sx={{ ...style.divider }} />

                    <FlexColumn muiStyle={{ ...style.userControls }}>
                        <UserSelector />
                        <PopoverUser />
                    </FlexColumn>
                </FlexColumn>
            </Panel>
        </>
    );
});
